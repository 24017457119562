import { Icon } from '@phosphor-icons/react';
import * as Dropdown from '@radix-ui/react-dropdown-menu';

export const DropdownMenu = ({
  children,
  items,
  ...props
}: {
  children: React.ReactNode;
  items: { label: string; icon?: Icon; onClick: () => void }[];
} & Dropdown.DropdownMenuContentProps) => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>{children}</Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          className="animate-in fade-in-0 shadow-soft bits-text-body-2 mt-1 flex w-[280px] flex-col rounded-xl bg-white p-3"
          {...props}
        >
          {items.map(({ icon: Icon, label, onClick }) => (
            <Dropdown.Item
              key={label}
              onClick={onClick}
              className="bits-text-body-1 flex cursor-pointer items-center gap-2 rounded-md p-1 data-[highlighted]:bg-black/5"
            >
              {Icon && <Icon className="size-5" />}
              {label}
            </Dropdown.Item>
          ))}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};
