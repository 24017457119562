import React from 'react';
import { Check } from '@phosphor-icons/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { cn } from './utils/cn';

export type CheckboxDropdownOption =
  | {
      type?: 'checkbox';
      id: string;
      label: string;
    }
  | {
      type: 'label';
      label: string;
    };

export interface CheckboxDropdownProps {
  children: React.ReactNode;
  title?: string;
  options: CheckboxDropdownOption[];
  values: string[];
  onCheckedItemsChange: (items: string[]) => void;
}

export const CheckboxDropdown = ({
  children,
  options,
  values,
  onCheckedItemsChange,
}: CheckboxDropdownProps) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          sideOffset={4}
          alignOffset={-8}
          className="animate-in fade-in-0 shadow-soft bits-text-body-2 mt-1 w-[300px] rounded-xl bg-white p-3"
        >
          <DropdownMenu.Group>
            {options.map((item, i) =>
              item.type === 'checkbox' ? (
                <DropdownMenu.CheckboxItem
                  autoFocus={i === 0 && true}
                  key={item.id}
                  className={dropdownItemClass}
                  checked={values.some((value) => value === item.id)}
                  onClick={() => {
                    onCheckedItemsChange(
                      values.some((value) => value === item.id)
                        ? values.filter((value) => value !== item.id)
                        : [...values, item.id]
                    );
                  }}
                >
                  <div className="border-fog flex size-5 items-center justify-center rounded-[3px] border bg-white">
                    <DropdownMenu.ItemIndicator>
                      <Check className="size-4" />
                    </DropdownMenu.ItemIndicator>
                  </div>
                  {item.label}
                </DropdownMenu.CheckboxItem>
              ) : (
                <DropdownMenu.Label
                  className="bits-text-subtitle-1 p-2"
                  key={item.label}
                >
                  {item.label}
                </DropdownMenu.Label>
              )
            )}
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export const dropdownItemClass = cn(
  'p-2 flex items-center gap-3 cursor-pointer',
  'focus-within:bg-tangerine-light/20 outline-none rounded-md',
  'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-30'
);
