import { ComponentType, ReactNode } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs';
import * as Tooltip from '@radix-ui/react-tooltip';

import { cn } from './utils/cn';

/**
 * Tabs that scale with the number of tabs, like Chrome's tabs.
 */
export const DynamicTabs = ({
  tabs,
  value,
  onValueChange,
}: {
  tabs: {
    icon: ComponentType<{ className?: string }>;
    label: string;
    value: string;
    indicator?: ReactNode;
    content: ReactNode;
    popoverContent?: ReactNode;
  }[];
  value: string;
  onValueChange: (value: string) => void;
}) => {
  const tabWidth = 100 / tabs.length;

  return (
    <Tabs
      defaultValue={tabs[0]?.value}
      value={value}
      onValueChange={onValueChange}
    >
      <Tooltip.Provider>
        <TabsList>
          <div className="bg-khaki-light z-10 flex gap-1 px-8">
            {tabs.map(
              ({
                label,
                value: tabValue,
                icon: Icon,
                indicator,
                popoverContent,
              }) => (
                <Tooltip.Root key={tabValue}>
                  <TabsTrigger value={tabValue} asChild>
                    <Tooltip.Trigger asChild>
                      <button
                        className={cn(
                          'friend group/tab min-w-0 rounded-md rounded-b-none p-2 pl-3 pr-0 bg-khaki-light',
                          'aria-selected:bg-sand-light',
                          'relative transition-all duration-200',
                          'before:absolute before:-left-0.5 before:top-1/2 before:-translate-y-1/2 before:h-1/2 before:w-px before:transition-all',
                          '[&:not(:first-child):not([aria-selected=true]):not(:hover):not(:focus)]:before:bg-khaki',
                          '[.friend.friend.friend[aria-selected=true]+&]:before:bg-transparent',
                          '[.friend.friend.friend:hover+&]:before:bg-transparent',
                          'border border-transparent hover:border-black/20 border-b-0',
                          'aria-selected:border-black/20',
                          'aria-selected:after:bg-sand-light aria-selected:after:-mb-px aria-selected:after:h-px aria-selected:after:w-full aria-selected:after:absolute aria-selected:after:left-0 aria-selected:after:bottom-0'
                        )}
                        style={{
                          maxWidth: `${tabWidth}%`,
                        }}
                      >
                        <div className="flex items-center gap-2 overflow-hidden text-nowrap">
                          <Icon className="size-6 shrink-0" />
                          <span className="overflow-hidden pr-3">{label}</span>
                        </div>
                        {indicator && (
                          <div className="absolute left-3 top-2">
                            {indicator}
                          </div>
                        )}
                        <div
                          className={cn(
                            'bg-gradient-to-r from-transparent to-khaki-light absolute top-1/2 -translate-y-1/2 right-0 h-1/2 w-5',
                            'group-aria-selected/tab:to-sand-light'
                          )}
                        />
                      </button>
                    </Tooltip.Trigger>
                  </TabsTrigger>
                  {popoverContent && (
                    <Tooltip.Portal>
                      <Tooltip.Content
                        side="bottom"
                        className="animate-in fade-in-25 slide-in-from-top-1 shadow-soft rounded-lg bg-white p-3 duration-200"
                      >
                        {popoverContent}
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  )}
                </Tooltip.Root>
              )
            )}
          </div>
        </TabsList>
      </Tooltip.Provider>

      <div className="bg-sand-light border-t border-black/20 p-8">
        {tabs.map((tab) => (
          <TabsContent key={tab.value} value={tab.value}>
            {tab.content}
          </TabsContent>
        ))}
      </div>
    </Tabs>
  );
};
