'use client';

import * as React from 'react';
import { Circle } from '@phosphor-icons/react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { cn } from '../utils/cn';
import { useUuid } from '../utils/use-uuid';

export interface ThemedRadioGroupProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> {
  label?: string;
  error?: string;
  hint?: string;
}

export const ThemedRadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  ThemedRadioGroupProps
>(({ className, label, hint, error, children, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn(
        'grid gap-3 text-theme-inputs-labelTextColor',
        error && 'text-theme-inputs-errorColor',
        className
      )}
      data-error={!!error}
      {...props}
      ref={ref}
    >
      {label && (
        <legend className="text-theme-inputs-labelFontSize text-current">
          {label}
        </legend>
      )}
      {children}

      {(hint || error) && (
        <div className="-mt-1">
          {hint && (
            <p className="text-theme-inputs-hintAndErrorFontSize text-theme-inputs-hintTextColor">
              {hint}
            </p>
          )}
          {error && typeof error === 'string' && (
            <p className="text-theme-inputs-hintAndErrorFontSize text-theme-inputs-errorColor">
              {error}
            </p>
          )}
        </div>
      )}
    </RadioGroupPrimitive.Root>
  );
});
ThemedRadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export interface ThemedRadioGroupItemProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>,
    'label'
  > {
  label: string;
  error?: string;
}

export const ThemedRadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  ThemedRadioGroupItemProps
>(({ className, label, error, ...props }, ref) => {
  const uuid = useUuid();

  return (
    <label
      className={cn(
        'flex gap-2 cursor-pointer',
        props.disabled && 'cursor-not-allowed opacity-50',
        '[[data-disabled]_&]:cursor-not-allowed [[data-disabled]_&]:opacity-50'
      )}
    >
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(
          'relative [top:calc(var(--typography-paragraph-fontSize)-16px)] aspect-square shrink-0 [width:calc(20px+var(--inputs-borderWidth))] [height:calc(20px+var(--inputs-borderWidth))] rounded-full',
          'border-current text-current [border-width:max(var(--inputs-borderWidth),1px)] [box-shadow:var(--inputs-shadow)]',
          'outline-none focus-visible:outline-2 focus-visible:outline-theme-general-focusColor',
          'data-[state=unchecked]:bg-theme-inputs-checkboxesAndRadios-unchecked-backgroundColor data-[state=checked]:bg-theme-inputs-checkboxesAndRadios-checked-backgroundColor',
          'data-[state=unchecked]:border-theme-inputs-checkboxesAndRadios-unchecked-borderColor data-[state=checked]:border-theme-inputs-checkboxesAndRadios-checked-borderColor',
          className
        )}
        aria-labelledby={uuid}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="animate-in zoom-in-50 relative flex items-center justify-center">
          <Circle
            weight="fill"
            className="fill-theme-inputs-checkboxesAndRadios-indicatorColor size-[11px]"
          />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
      <p
        aria-hidden
        className={cn('text-current text-theme-typography-paragraph-fontSize')}
        id={uuid}
      >
        {label}
      </p>
    </label>
  );
});
ThemedRadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;
