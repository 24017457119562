import {
  availableFeatureFlags,
  useFeatureFlag,
} from './feature-flags-provider';

export const useCanUseEntitiesInApplications = () => {
  return useFeatureFlag(
    availableFeatureFlags.CAN_USE_ENTITIES_IN_APPLICATIONS,
    false
  );
};
