import { z } from 'zod';

import {
  organizationResourceTypeSchema,
  personResourceTypeSchema,
} from '@/lib/validations/bits-api/applications/report';
import {
  ListRequest,
  ListSorting,
} from '@/lib/validations/bits-api/listing-request';

import { EntityType } from '../validations/bits-api/entities/get-entity-response';

export type RouteQuery = Record<string, string | number | boolean | undefined>;
export const stringifyQuery = (query: RouteQuery | undefined) => {
  if (!query) {
    return '';
  }

  const filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([, value]) => value !== undefined)
  ) as Record<string, string>;

  return new URLSearchParams(filteredQuery).toString();
};

const createRoute = (path: string, query?: RouteQuery) => {
  if (!query) {
    return path;
  }

  return `${path}?${stringifyQuery(query)}`;
};

export const apiRoutes = {
  me: '/api/me',

  createUser: '/api/account/create-user',
  createCustomer: '/api/account/create-customer',
  getCustomer: (customerId: string) =>
    createRoute(`/api/customers/${customerId}`),
  listCustomerUsers: () => `/api/customers/users/list`,

  listWorkflows: '/api/workflows',
  updateWorkflow: (workflowId: string) =>
    createRoute(`/api/workflows/${workflowId}`),
  createWorkflow: '/api/workflows',
  deleteWorkflow: (workflowId: string) =>
    createRoute(`/api/workflows/${workflowId}`),
  availableMarkets: '/api/workflows/available-markets',
  availableLocales: '/api/locales',
  getWorkflow: (workflowId: string) =>
    createRoute(`/api/workflows/${workflowId}`),
  getMarkets: (workflowId: string) =>
    createRoute(`/api/workflows/${workflowId}/markets`),
  getMarket: (workflowId: string, marketId: string) =>
    createRoute(`/api/workflows/${workflowId}/markets/${marketId}`),
  deleteMarket: (workflowId: string, marketId: string) =>
    createRoute(`/api/workflows/${workflowId}/markets/${marketId}`),
  createMarket: (workflowId: string) =>
    createRoute(`/api/workflows/${workflowId}/markets`),

  // Themes
  listThemes: '/api/customers/themes',
  createTheme: '/api/customers/themes',
  getTheme: (themeId: string) =>
    createRoute(`/api/customers/themes/${themeId}`),
  deleteTheme: (themeId: string) =>
    createRoute(`/api/customers/themes/${themeId}`),
  putTheme: (themeId: string) =>
    createRoute(`/api/customers/themes/${themeId}`),
  postThemeAsset: '/api/customers/themes/assets',

  // APi
  listApiKeys: '/api/api-keys',
  createApiKey: '/api/api-keys',
  getStepsMetadata: `/api/steps/metadata`,

  // Questionnaire
  getQuestionnaire: (marketId: string) =>
    createRoute(`/api/markets/${marketId}/questionnaire`),
  patchQuestionGroup: (questionnaireId: string) =>
    createRoute(`/api/questionnaire/${questionnaireId}/question-group`),
  patchQuestion: (
    questionnaireId: string,
    questionGroupId: string,
    questionId: string
  ) =>
    createRoute(
      `/api/questionnaire/${questionnaireId}/question-group/${questionGroupId}/question/${questionId}`
    ),
  patchQuestionAlternative: (
    questionnaireId: string,
    questionGroupId: string,
    questionId: string,
    alternativeId: string
  ) =>
    createRoute(
      `/api/questionnaire/${questionnaireId}/question-group/${questionGroupId}/question/${questionId}/alternative/${alternativeId}`
    ),

  // Applications

  getApplications: (
    applicantType: string // DEPRECATED
  ) => createRoute('/api/applications/?applicant_type=' + applicantType),
  listApplications: (
    filters: ListRequest['filters'],
    limit: number,
    page: number,
    sortBy?: ListSorting,
    query?: string
  ) =>
    `/api/applications?payload=${JSON.stringify({
      filters,
      limit,
      offset: (page - 1) * limit,
      sorting: [sortBy],
      query,
    })}`,
  createApplicationSession: '/api/applications/create-application-session',
  getApplication: (applicationId: string) =>
    createRoute(`/api/applications/${applicationId}`),
  getApplicationDetail: (applicationId: string) =>
    createRoute(`/api/applications/${applicationId}/application-detail`),
  getOrganizationApplicationDetail: (
    applicationId: string,
    resourceType: z.infer<typeof organizationResourceTypeSchema>
  ) =>
    createRoute(
      `/api/applications/${applicationId}/application-detail/organization/${resourceType}`
    ),
  getPersonApplicationDetail: (
    applicationId: string,
    resourceType: z.infer<typeof personResourceTypeSchema>
  ) =>
    createRoute(
      `/api/applications/${applicationId}/application-detail/person/${resourceType}`
    ),
  declineApplication: (applicationId?: string) =>
    createRoute(`/api/applications/${applicationId}/status/declined`),
  approveApplication: (applicationId?: string) =>
    createRoute(`/api/applications/${applicationId}/status/approved`),
  postApplicationStatus: (
    applicationId: string,
    status: 'approved' | 'declined'
  ) => `/api/applications/${applicationId}/status/${status}`,
  getApplicationEntities: (applicationId: string) =>
    `/api/applications/${applicationId}/entities`,

  // Documents
  getDocuments: (applicationId: string) =>
    createRoute(`/api/applications/${applicationId}/documents`),
  getDocumentPreSignedUrl: (applicationId: string) =>
    createRoute(`/api/applications/${applicationId}/documents/pre-signed-url`),
  getDocumentDownloadUrl: (applicationId?: string, documentId?: string) =>
    createRoute(`/api/applications/${applicationId}/documents/${documentId}`),
  deleteDocument: (applicationId?: string, documentId?: string) =>
    createRoute(`/api/applications/${applicationId}/documents/${documentId}`),
  getEntityDocumentDownloadUrl: (key: string) =>
    createRoute(`/api/documents/${key}`),

  postCustomerDocumentPresignedUrl: () =>
    createRoute(`/api/customers/documents`),
  deleteCustomerDocument: (documentId: string) =>
    createRoute(`/api/customers/documents/${documentId}`),

  listMarketSteps: (workflowId: string, marketId: string) =>
    createRoute(`/api/workflows/${workflowId}/markets/${marketId}/steps`),
  addMarketStep: (workflowId: string, marketId: string) =>
    createRoute(`/api/workflows/${workflowId}/markets/${marketId}/steps`),
  deleteMarketStep: (workflowId: string, marketId: string, stepId: string) =>
    createRoute(
      `/api/workflows/${workflowId}/markets/${marketId}/steps/${stepId}`
    ),
  getMarketSettings: (workflowId: string, marketId: string) =>
    createRoute(`/api/workflows/${workflowId}/markets/${marketId}/settings`),
  patchMarketSettings: (workflowId: string, marketId: string) =>
    createRoute(`/api/workflows/${workflowId}/markets/${marketId}/settings`),
  createWebhook: '/api/webhooks',
  getWebhook: (webhookId: string) => `/api/webhooks/${webhookId}`,
  listWebhooks: '/api/webhooks',
  deleteWebhook: (webhookId: string) => `/api/webhooks/${webhookId}`,
  getProviderConfiguration: (
    workflowId: string,
    marketId: string,
    stepId: string,
    providerId: string
  ) =>
    createRoute(
      `/api/workflows/${workflowId}/markets/${marketId}/steps/${stepId}/providers/${providerId}/configuration`
    ),
  getMonitorConfig: () => `/api/monitoring`,
  deleteMonitorConfig: (monitorConfigId: string) =>
    `/api/monitoring/${monitorConfigId}`,
  postCreateMonitorConfig: () => `/api/monitoring`,
  postProviderConfiguration: (
    workflowId: string,
    marketId: string,
    stepId: string,
    providerId: string
  ) =>
    createRoute(
      `/api/workflows/${workflowId}/markets/${marketId}/steps/${stepId}/providers/${providerId}/configuration`
    ),

  // Risk
  createRiskConfigCollection: () => '/api/risk/collections',
  updateRiskConfigCollection: (collectionId: string) =>
    `/api/risk/collections/${collectionId}`,
  listRiskConfigCollections: () => '/api/risk/collections',
  getRiskConfigCollection: (collectionId: string) =>
    `/api/risk/collections/${collectionId}`,

  createRiskConfig: '/api/risk/config',
  getRiskConfig: (configId: string) => `/api/risk/config/${configId}`,
  saveRiskConfig: (configId: string) => `/api/risk/config/${configId}`,
  deleteRiskConfig: (configId: string) => `/api/risk/config/${configId}/delete`,
  publishRiskConfig: (configId: string) =>
    `/api/risk/config/${configId}/publish`,
  unpublishRiskConfig: (configId: string) =>
    `/api/risk/config/${configId}/unpublish`,
  getRiskScore: (entityId: string) => `/api/risk/score/${entityId}`,

  listAvailableRiskVariables: (configId: string) =>
    `/api/risk/config/${configId}/variables`,

  // API Credentials
  listApiCredentials: '/api/v1/customers/credentials/api',
  getApiCredentials: (credentialId: string) =>
    createRoute(`/api/v1/customers/credentials/api/${credentialId}`),
  createApiCredentials: '/api/v1/customers/credentials/api',
  deleteApiCredentials: (credentialId: string) =>
    createRoute(`/api/v1/customers/credentials/api/${credentialId}`),

  // Entities
  listEntities: (
    entityType: EntityType,
    filters: ListRequest['filters'],
    limit: number,
    page: number,
    sortBy?: ListSorting,
    query?: string
  ) =>
    `/api/entities?payload=${JSON.stringify({
      entityType,
      filters,
      limit,
      offset: (page - 1) * limit,
      sorting: [sortBy],
      query,
    })}`,
  getEntity: (entityId: string) => createRoute(`/api/entities/${entityId}`),
  getEntityResource: (entityId: string, resource: string) =>
    createRoute(`/api/entities/${entityId}/${resource}`),
  createApplicationLinkForEntity: (entityId: string) =>
    createRoute(`/api/entities/${entityId}/create-application-link`),

  // Entity PEP Matches
  screeningMatchRPC: (rpc: 'get' | 'confirm' | 'reject') =>
    `/api/entities/screening-matches/${rpc}`,

  // Screening stuff
  updateScreeningMatchStatus: () => `/api/screenings/matches/status`,
  getScreeningMatch: (screeningMatchId: string) =>
    `/api/screenings/matches/${screeningMatchId}`,
  listScreeningMatches: (entityId: string) =>
    `/api/screenings/matches/list/${entityId}`,

  // Cases
  connectedCases: (caseId: string) => `/api/cases/${caseId}/connected-cases`,
  listCases: (
    filters: ListRequest['filters'],
    limit: number,
    page: number,
    sortBy?: ListSorting,
    query?: string
  ) =>
    `/api/cases?payload=${JSON.stringify({
      filters,
      limit,
      offset: (page - 1) * limit,
      sorting: [sortBy],
      query,
    })}`,
  getCase: (caseId: string) => `/api/cases/${caseId}`,
  updateCaseAssignee: (caseId: string) => `/api/cases/${caseId}/assign`,
  getActivityLog: (caseId: string) => `/api/cases/${caseId}/activity-log`,
  postCaseComment: (caseId: string) => `/api/cases/${caseId}/comments`,
  postCaseStatus: (caseId: string) => `/api/cases/${caseId}/status`,
};
