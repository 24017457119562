export const bitsI18nNextPlugin = <
  RemoteNamespaces extends readonly string[],
  LocalLazyNamespaces extends readonly string[],
>({
  remotePath = '/api/locales/{{lng}}/{{ns}}',
  localPath = '/locales/{{lng}}/{{ns}}',
  apiNamespaces,
  localLazyNamespaces,
}: {
  remotePath?: string;
  localPath?: string;
  apiNamespaces: RemoteNamespaces;
  localLazyNamespaces: LocalLazyNamespaces;
}) => {
  return {
    loadPath: remotePath,
    apiNamespaces,
    request: async (
      options: { apiNamespaces: string[] },
      url: string,
      _: unknown,
      callback: (
        error: unknown,
        result: { data: unknown; status: number } | null
      ) => void
    ) => {
      const [_0, _1, _2, lang, namespace] = url.split('/');

      // load api namespace
      if (options.apiNamespaces.includes(namespace as string)) {
        const request = await fetch(`/api/locales/${lang}/${namespace}`);
        if (request.ok) {
          callback(null, { data: await request.json(), status: 200 });
        } else {
          callback(new Error('not found'), null);
        }
        return;
      }

      // load local namespace
      if (
        namespace &&
        localLazyNamespaces.includes(
          namespace as (typeof localLazyNamespaces)[number]
        )
      ) {
        const request = await fetch(`/locales/${lang}/${namespace}.json`);
        if (request.ok) {
          callback(null, { data: await request.json(), status: 200 });
        } else {
          callback(new Error('not found'), null);
        }
        return;
      }

      // tell i18next that we don't have the remote namespace, use fallback
      callback(new Error('no remote namespace'), null);
    },
  };
};
